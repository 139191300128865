export const environment = {
  production: true,
  hideElttoUi: false,
  apiUrl: 'https://auth.qa2.mountainseedapp.com/api',
  elttoUrl: 'https://eltto.qa2.mountainseedapp.com/',
  miUrl: 'https://mi.qa2.mountainseedapp.com/',
  ngxs: {
    plugins: [],
  },
  gaId: 'G-N8S2M90J40',
};
